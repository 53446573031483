import { NoResult } from '@flowardco/fui-no-result';
import { useLocalTranslator } from '@/hooks/useLocalTranslator';
function NotFoundPage() {
  const { __T } = useLocalTranslator();

  return (
    <div>
      <NoResult
        className='fui-h-full fui-flex-1 !fui-justify-between'
        icon={null}
        text={__T('Page Not Found')}
      />
    </div>
  );
}

export default NotFoundPage;
