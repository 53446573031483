export const PageLayout: React.FC<any> = ({ children }) => {
  return (
    <div className=' fui-m-auto fui-flex fui-min-h-[100vh] fui-w-full fui-max-w-[400px] fui-items-center fui-justify-center'>
      <main className='fui-w-full'>
        <img
          src='https://floward.imgix.net/web/Files/attachment/logos/logo.svg'
          alt='floward-logo'
          className='fui-m-auto fui-mb-30px fui-max-w-[200px]'
        />

        {children}
      </main>
    </div>
  );
};
