import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
} from 'react-router-dom';
import { RouteError } from '@flowardco/app-shared/components/RouteError';
import RootLayoutPage from '@/pages/Root';
import ResetPasswordPage from '@/pages/ResetPassword';
import LayoutPage from '@/pages/Root/Layout';
import NotFoundPage from '@/components/NotFoundPage';
export const routesConfig = createBrowserRouter(
  createRoutesFromElements(
    <Route path='/' element={<RootLayoutPage />}>
      <Route
        id='root'
        path=':lang'
        element={<LayoutPage />}
        errorElement={<RouteError />}
        shouldRevalidate={() => false}
      >
        <Route
          path={'reset-password'}
          element={<ResetPasswordPage />}
          errorElement={<RouteError />}
        />
        {/* Redirect all non matching request to website */}
        <Route path='*' element={<NotFoundPage />} />
      </Route>
      {/* Redirect all non matching request to website */}
      <Route path='*' element={<NotFoundPage />} />
    </Route>
  )
);
