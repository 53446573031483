import {
  errorLoggerV2,
  ddLogger,
} from '@flowardco/app-shared/helpers/ddClientLogs';
import { addToast } from '@flowardco/flib-util';
import {
  internalHttpClient,
  API_ENDPOINTS,
  API_SERVICE_URLS,
  formatUrlParams,
  formatHttpResponse,
} from '@flowardco/flib-helpers';

const userClient = internalHttpClient({ baseURL: API_SERVICE_URLS.USER });

// Update Password
export const resetPassword = async (tokenId: string, values: any, __T: any) => {
  try {
    const res = await userClient.put(
      formatUrlParams(API_ENDPOINTS.idApp.forgetpassword, {
        token: tokenId,
      }),
      {
        ...values,
      }
    );
    const data = formatHttpResponse(res);
    addToast(__T('Updated password successfully'));
    return data;
  } catch (error: any) {
    errorLoggerV2('ResetPassword::ResetPassword', error);
    ddLogger(
      'ResetPasswordPage',
      { error: error?.response?.data?.message },
      'warn'
    );
    addToast(error?.response?.data?.message || 'Some error occured', 'error');
    throw new Error(error?.response?.data?.message || 'Some error occured');
  }
};

export const checkTokenValidation = async (token: string, tokenId: string) => {
  try {
    const res = await userClient.post(
      formatUrlParams(API_ENDPOINTS.idApp.check, {
        token: tokenId,
      }),
      {
        token,
      }
    );
    const data = formatHttpResponse(res);
    return data;
  } catch (error: any) {
    errorLoggerV2('checkTokenValidation::checkTokenValidation', error);
    ddLogger(
      'ResetPasswordPage',
      { error: error?.response?.data?.message },
      'warn'
    );
    addToast(error?.response?.data?.message || 'Some error occured', 'error');
    throw new Error(error?.response?.data?.message || 'Some error occured');
  }
};
