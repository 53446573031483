import { checkTokenValidation } from '@/api/id.service';
import {
  SET_IS_VALID_TOKEN,
  SET_STRING_TOKEN,
} from '@/Context/LocalAppContext';
import { useLocalTranslator } from '@/hooks/useLocalTranslator';
import { useLocalApp } from '@/hooks/useLocalTranslator/useLocalApp';
import { useValidations } from '@flowardco/flib-hooks';
import { Button } from '@flowardco/fui-button';
import { InputField } from '@flowardco/fui-input-field';
import { useFormik } from 'formik';
import { useState } from 'react';
import * as Yup from 'yup';

export interface FormValues {
  token: string;
}
export const ResetPasswordSecurityCode = () => {
  const { __T } = useLocalTranslator();
  const { validations } = useValidations(__T);
  const [isProcessing, setIsProcessing] = useState(false);
  const { appDispatch } = useLocalApp();
  const query = new URLSearchParams(location.search);
  const tokenId = query.get('tokenId');
  const initialValues: FormValues = {
    token: '',
  };

  const validationSchema = Yup.object().shape({
    token: validations.securityCode,
  });

  const handleSubmit = async (values: FormValues) => {
    try {
      setIsProcessing(true);
      if (tokenId) {
        const res = await checkTokenValidation(values.token, tokenId);
        if (res) {
          appDispatch({
            type: SET_STRING_TOKEN,
            payload: {
              isAppLoading: false,
              tokenString: values.token,
            },
          });
          appDispatch({
            type: SET_IS_VALID_TOKEN,
            payload: {
              isAppLoading: false,
              isValidToken: res?.success,
            },
          });
        }
      }
    } finally {
      setIsProcessing(false);
    }
  };

  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmit,
    validationSchema,
  });

  return (
    <div className='fui-rounded-lg fui-bg-white  fui-shadow-md fui-transition fui-duration-300 hover:fui-shadow-lg'>
      <h2 className='fui-m-0 fui-px-4 fui-py-18px fui-text-left fui-text-xl fui-font-bold rtl:fui-text-right'>
        {__T('Enter security code')}
      </h2>
      <form onSubmit={formik.handleSubmit}>
        <div className='fui-border fui-border-[#eee] fui-px-4 fui-py-18px'>
          <p className='fui-txt-md fui-mb-20px rtl:fui-text-right'>
            {__T(
              'Please check your email for a message with your code. Your code is 6 numbers long.'
            )}
          </p>

          <InputField
            id='token'
            name='token'
            type='text'
            required
            maxLength={6}
            labelClass='fui-text-base fui-font-bold fui-mb-0'
            value={formik.values.token}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            label={__T('Enter code')}
            wrapperClassName='fui-w-full'
            inputFieldClass='fui-mb-4 fui-rounded-[0.25rem]'
            errorMessage={
              formik.errors.token && formik.touched.token
                ? formik.errors.token
                : undefined
            }
          />
        </div>
        <div className='fui-flex fui-items-center fui-justify-between fui-px-4 fui-py-18px rtl:fui-flex-row-reverse'>
          <a href={'https://floward.com/'} className='fui-underline'>
            {__T("Didn't get a code?")}
          </a>
          <Button
            label={__T('Confirm')}
            type='submit'
            isProcessing={isProcessing}
            disabled={!tokenId}
          />
        </div>
      </form>
    </div>
  );
};
