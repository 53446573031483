import { useLocalTranslator } from '@/hooks/useLocalTranslator';
import { InputField } from '@flowardco/fui-input-field';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useValidations } from '@flowardco/flib-hooks';
import { Button } from '@flowardco/fui-button';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { AppErrorBoundary } from '@flowardco/fui-app-error-boundary';
import { resetPassword } from '@/api/id.service';
import { useLocalApp } from '@/hooks/useLocalApp';

export interface FormValues {
  newPassword: string;
  confirmedPassword: string;
}
export const ResetPasswordForm = () => {
  const { __T } = useLocalTranslator();
  const { validations } = useValidations(__T);
  const [isProcessing, setIsProcessing] = useState(false);
  const { appState } = useLocalApp();

  const handleSubmit = async (values: FormValues) => {
    try {
      setIsProcessing(true);
      if (values.newPassword.trim() !== values.confirmedPassword.trim()) {
        toast.error(__T('Passwords are not identical.'));
      } else {
        const query = new URLSearchParams(location.search);
        const token = query.get('tokenId');
        const tokenString = query.get('tokenString') || appState?.tokenString;
        const data = {
          newPassword: values.newPassword,
          token: tokenString,
        };
        if (token && tokenString) {
          //   Add your password reset API call or other logic
          const res = await resetPassword(token, data, __T);
          if (res) {
            window.location.href = 'https://floward.com/';
          }
        }
      }
    } finally {
      setIsProcessing(false);
    }
  };

  const initialValues: FormValues = {
    newPassword: '',
    confirmedPassword: '',
  };

  const validationSchema = Yup.object().shape({
    newPassword: validations.password,
    confirmedPassword: validations.password,
  });

  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmit,
    validationSchema,
  });

  return (
    <div className=''>
      <h5 className='fui-mb-10px fui-text-center fui-text-2xl'>
        {__T('Reset Password')}
      </h5>
      <AppErrorBoundary>
        <form
          onSubmit={formik.handleSubmit}
          className='fui-flex fui-w-full fui-flex-col fui-items-start fui-justify-center'
        >
          <InputField
            label={__T('New password')}
            id='newPassword'
            labelClass='fui-text-base fui-font-bold fui-mb-0'
            name='newPassword'
            type='password'
            wrapperClassName='fui-w-full'
            inputFieldClass='fui-mb-4 fui-rounded-[0.25rem]'
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            errorMessage={
              formik.errors.newPassword && formik.touched.newPassword
                ? formik.errors.newPassword
                : undefined
            }
            value={formik.values.newPassword}
          />
          <InputField
            label={__T('Confirm new password')}
            id='confirmedPassword'
            labelClass='fui-text-base fui-font-bold fui-mb-0'
            name='confirmedPassword'
            type='password'
            wrapperClassName='fui-w-full'
            inputFieldClass='fui-mb-4 fui-rounded-[0.25rem]'
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            errorMessage={
              formik.errors.confirmedPassword &&
              formik.touched.confirmedPassword
                ? formik.errors.confirmedPassword
                : undefined
            }
            value={formik.values.confirmedPassword}
          />
          <Button
            label={__T('Confirm')}
            type='submit'
            className='fui-mb-4 fui-w-full'
            isProcessing={isProcessing}
          />
        </form>
      </AppErrorBoundary>
    </div>
  );
};
