import React, { createContext, useReducer } from 'react';
export interface LocalAppContextProps {
  isAppLoading: boolean;
  lang: string;
  isValidToken: boolean;
  tokenString: string;
}

export interface AppStateActionProps {
  type: string;
  payload?: any;
}

export const defaultLocalAppContext: LocalAppContextProps = {
  isAppLoading: true,
  lang: 'en',
  isValidToken: false,
  tokenString: '',
};

export const SET_APP_LOADING = 'SET_APP_LOADING';
export const SET_IS_VALID_TOKEN = 'SET_IS_VALID_TOKEN';
export const SET_STRING_TOKEN = 'SET_STRING_TOKEN';

export const LocalAppContext = createContext<any>(defaultLocalAppContext);

export const appReducer = (
  state: LocalAppContextProps,
  action: AppStateActionProps
): any => {
  switch (action.type) {
    case SET_IS_VALID_TOKEN:
      return {
        ...state,
        isAppLoading: false,
        lang: action.payload.lang,
        isValidToken: action.payload.isValidToken,
      };
    case SET_STRING_TOKEN:
      return {
        ...state,
        tokenString: action.payload.tokenString,
        lang: action.payload.lang,
      };
    case SET_APP_LOADING:
      return { ...state, isAppLoading: action.payload };

    default:
      return state;
  }
};

export const LocalAppContextProvider: React.FC<{
  children?: React.ReactNode;
}> = ({ ...props }) => {
  const [appState, appDispatch] = useReducer(appReducer, {
    ...defaultLocalAppContext,
  });

  const appConfigs = {
    appState,
    appDispatch,
  };

  return (
    <LocalAppContext.Provider value={appConfigs}>
      {props.children}
    </LocalAppContext.Provider>
  );
};
