import { useContext } from 'react';
import { errorLoggerV2 } from '@flowardco/app-shared/helpers/ddClientLogs';
import {
  LocalAppContext,
  type LocalAppContextProps,
  SET_IS_VALID_TOKEN,
} from '@/Context/LocalAppContext';
import { checkTokenValidation } from '@/api/id.service';
import { useParams } from 'react-router-dom';

export interface AppHookInterface {
  appState: LocalAppContextProps;
  appDispatch: any;
  checkTokenStringValidation: () => Promise<any>;
}

export const useLocalApp = (): AppHookInterface => {
  const app = useContext(LocalAppContext);
  const { appDispatch } = app;
  const { lang } = useParams();
  const query = new URLSearchParams(location.search);
  const tokenId = query.get('tokenId');
  const tokenString = query.get('tokenString');
  const sourceSite = query.get('sourceSite');
  const checkTokenStringValidation = async () => {
    try {
      if (tokenString && tokenId && sourceSite) {
        const res: any = await checkTokenValidation(tokenString, tokenId);
        if (res) {
          appDispatch({
            type: SET_IS_VALID_TOKEN,
            payload: {
              isAppLoading: false,
              isValidToken: res?.success,
              lang,
            },
          });
        }
      }
    } catch (error: any) {
      errorLoggerV2('Failed Checking Token Validation', error, {
        metaData: {
          action: 'useLocalApp:checkTokenStringValidation',
        },
      });
    }
  };

  return {
    ...app,
    checkTokenStringValidation,
  };
};
