/* eslint-disable no-nested-ternary */
import { PageLayout } from '@/components/PageLayout';
import { ResetPasswordForm } from '@/components/ResetPasswordForm';
import { ResetPasswordSecurityCode } from '@/components/ResetPasswordSecurityCode';
import { useLocalApp } from '@/hooks/useLocalApp';
import { useLocalTranslator } from '@/hooks/useLocalTranslator';
import { AppLoader } from '@flowardco/app-shared/components/AppLoader';
import { useEffect } from 'react';

const ResetPasswordPage = () => {
  const { __T } = useLocalTranslator();
  const query = new URLSearchParams(location.search);
  const tokenId = query.get('tokenId');
  const tokenString = query.get('tokenString');
  const sourceSite = query.get('sourceSite');
  const { checkTokenStringValidation, appState } = useLocalApp();
  useEffect(() => {
    if (tokenString && tokenId && sourceSite) {
      checkTokenStringValidation();
    }
    // if query params doesn't exist in the url redirect to source site
    if (!tokenId || !sourceSite) {
      window.location.href = 'https://floward.com/';
    }
  }, [tokenString, tokenId, sourceSite]);
  const renderContent = () => {
    if (appState.isAppLoading && tokenString) {
      return <AppLoader />;
    }
    // if string doesn't exist in the url show security code form
    if (!tokenString && !appState?.isValidToken) {
      return <ResetPasswordSecurityCode />;
    }

    return !appState?.isValidToken ? (
      <p className='fui-text-center fui-font-bold'>
        {__T('The provided token is either expired or invalid.')}
      </p>
    ) : (
      <ResetPasswordForm />
    );
  };

  return <PageLayout>{renderContent()}</PageLayout>;
};

export default ResetPasswordPage;
